@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    background-color: #00040f;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #00040f;
  }

  /* Prevent child elements from shrinking when content is taller than the screen */
  #root > * {
    flex-shrink: 0;
  }

  :root {
    --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);

    /* Obsidian Base Color */
    --color-base-00: #1e1e1e;
    --color-base-05: #222222;
    --color-base-10: #242424;
    --color-base-20: #262626;
    --color-base-25: #2a2a2a;
    --color-base-30: #363636;
    --color-base-35: #3f3f3f;
    --color-base-38: #484848;
    --color-base-40: #555;
    --color-base-45: #5f5f5f;
    --color-base-50: #666;
    --color-base-60: #999;
    --color-base-70: #bababa;
    --color-base-75: #c0c0c0;
    --color-base-100: #dadada;

    --color-base-81: #252525;
    --color-base-82: #2d2d2d;
    --color-base-83: #111;

    /* rgb values */
    --color-primary: #00040f;
    /* --rgb-wavery-blue: 119, 215, 253; */
    --rgb-wavery-blue: #67e8f9; /* tailwind: cyan-300 */
  }
}

@layer utilities {
  .section.bg-white + .section.bg-white {
    border-top: 1px solid #f0f0f0;
  }
  .pattern-dots {
    background-image: radial-gradient(currentColor 2px, transparent 2px);
    background-size: calc(10 * 2px) calc(10 * 2px);
  }

  .gray-background {
    background-color: rgba(15, 15, 15, 1);
  }

  .gray-border {
    border: 2px solid rgba(37, 37, 37, 1);
  }
}

.rcorners {
  z-index: 10;
  overflow: hidden;
  border-radius: 25px;
}

.backlight {
  box-shadow: 0px 0px 100px -20px rgba(var(--rgb-wavery-blue), 1);
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #b6d1d2 -43.27%,
    #bceef0 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(144.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
}

/* Notification.css */

.notification-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.notification-message {
  display: flex;
  align-items: center;
  background-color: #f0f4ff;
  border: 1px solid #b3c7ff;
  color: #0033cc;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 16px;
  max-width: 90vw;
}

.notification-container.error .notification-message {
  background-color: #ffe6e6;
  border-color: #ff9999;
  color: #cc0000;
}

.notification-container.success .notification-message {
  background-color: #e6ffe6;
  border-color: #99ff99;
  color: #009900;
}

.notification-message .icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
}

.notification-message span {
  display: inline-block;
  vertical-align: middle;
}

/* PRICING CARD */
.PriceColumn {
  overflow: hidden;
  border-radius: 16px;
  background-color: black;
  box-shadow: var(--card-shadow);
  color: var(--color-base-100);
  box-shadow: 0px 0px 100px -20px rgba(var(--rgb-wavery-blue), 1);

  transition-property: transform, box-shadow, background-color;
  transition-duration: 230ms;
  transition-timing-function: ease-out;
}

/* .PriceColumn {
  background-color: var(--color-base-00);
  border-radius: 16px;
  box-shadow: var(--card-shadow);
} */

@media (min-width: 768px) {
  /* sm breakpoint */
  .PriceColumn {
    box-shadow: 0px 0px 80px -20px rgba(var(--rgb-wavery-blue), 0.7);
  }
  .PriceColumn {
    transform: scale(1.008);
    box-shadow: 0px 0px 100px -20px rgba(var(--rgb-wavery-blue), 1);
  }
}
